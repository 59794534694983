<script setup>
  import { computed, ref, watch, watchEffect } from 'vue';
  import { useLocationStore } from '../../../../../stores/account/location.store';
  import { useListingStore } from '~/stores/account/listings.store';

  const emit = defineEmits(['update:city']);
  const locationStore = useLocationStore();
  const listingStore = useListingStore();
  await locationStore.fetchCities();

  const topCities = computed(() => locationStore.citiesGetter());

  // Track selected cities at each level
  const selectedCities = ref([]);  // Start with top-level selection

  const getChildren = (cityId) => {
    const city = selectedCities.value.find(c => c.id === cityId);
    return city ? city.children : [];
  };

  const onSelectCity = (level, cityId) => {
    if (level === 'parent') {
      const selectedCity = topCities.value.find(c => c.id == cityId);
      if (selectedCity) {
        selectedCities.value = [selectedCity];
      }
    } else {
      const children = selectedCities.value[level]?.children;
      if (children?.length) {
        const selectedChildCity = children.find(c => c.id == cityId);
        if (selectedChildCity) {
          selectedCities.value = [
            ...selectedCities.value.slice(0, level + 1),
            selectedChildCity
          ];
        }
      }
    }

    const city_id = selectedCities.value.at(-1)?.id;
    emit('update:city', city_id);
  };

  watch(
    () => {},
    () => {}
  );

</script>

<template>
  <div class="relative w-full">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal text-left">
      <span>City</span>
    </h3>

    <div 
      class="flex flex-col gap-3 justify-between mb-3 mt-2 items-center"
    >
      <select 
        @change="onSelectCity('parent', $event.target.value)"
        class="bg-white w-full block h-12  border text-gray-900 text-sm p-3 rounded-lg focus:ring-2 focus:ring-inset focus:ring-indigo-600"
      >
        <option 
          v-for="city in topCities" 
          :key="city.id" 
          :value="city.id"
        >
          {{ city.name }}
        </option>
      </select>

      <div 
        v-for="(selectedCity, level) in selectedCities" 
        :key="level"
        class="rounded-lg"
      >
        <select 
          v-if="selectedCity?.children?.length" 
          @change="onSelectCity(level, $event.target.value)"
          class="bg-white w-full block h-12  border text-gray-900 text-sm p-3 rounded-lg focus:ring-2 focus:ring-inset focus:ring-indigo-600"
        >
          <option disabled selected>Select a city</option>
          <option 
            v-for="city in selectedCity.children" 
            :key="city.id" 
            :value="city.id"
          >
            {{ city.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>